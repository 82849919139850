$serenity: #92a8d1;

$mimosa: #efc050;

$greenery: #88b04b;

$default-accent: #ff4081;

$turquoise: #45b8ac;

$butter-cream: #efe1ce;

$desert-mist: #e0b589;

$classic-blue: #34568b;

$tangerine-tango: #dd4124;

$emerald: #009b77;

$neon-red: #ff3131;

$carmine: #d70040;

$shamrock-green: #009e60;

$amber: #ffbf00;

$lightgrayish: #bcbcbc;

$grayish: #5050508a;

$yellowish: rgb(218, 221, 40);

$carmine: #d70040;

$desert-mist: #e0b589;

// Pbx call icons colors
$missed-call-color: #E61B23;
$incoming-call-color: #FFA402;
$successfull-call-color: #007b2b;
$initiated-call-color: #32CD32;
$ongoing-call-color: #4c72d9;
$all-busy-call-color: #ff4c4c;
$non-working-hours-call-color: #4c6eff;
$agent-free-call-color: #3744fe;
$hangup-at-welcome-call-color: #ddff00;
$connection-failed-call-color: $lightgrayish;
$busy-call-color: $yellowish;
$failed-call-color: $grayish;
$no-answer-call-color: $carmine;

@function color-shade($color, $percent) {
  @return mix(black, $color, $percent);
}

@function color-tint($color, $percent) {
  @return mix(white, $color, $percent);
}

:root {
  --missed-call-base: #{$missed-call-color};
  --missed-call-light: #{color-tint($missed-call-color, 20%)};
  --missed-call-dark: #{color-shade($missed-call-color, 20%)};

  --incoming-call-base: #{$incoming-call-color};
  --incoming-call-light: #{color-tint($incoming-call-color, 20%)};
  --incoming-call-dark: #{color-shade($incoming-call-color, 20%)};

  --outgoing-call-base: #{$successfull-call-color};
  --outgoing-call-light: #{color-tint($successfull-call-color, 20%)};
  --outgoing-call-dark: #{color-shade($successfull-call-color, 20%)};

  --initiated-call-base: #{$initiated-call-color};
  --initiated-call-light: #{color-tint($initiated-call-color, 20%)};
  --initiated-call-dark: #{color-shade($initiated-call-color, 20%)};

  --successful-call-base: #{$successfull-call-color};
  --successful-call-light: #{color-tint($successfull-call-color, 20%)};
  --successful-call-dark: #{color-shade($successfull-call-color, 20%)};

  --all-busy-call-base: #{$all-busy-call-color};
  --all-busy-call-light: #{color-tint($all-busy-call-color, 20%)};
  --all-busy-call-dark: #{color-shade($all-busy-call-color, 20%)};

  --non-working-hours-call-base: #{$non-working-hours-call-color};
  --non-working-hours-call-light: #{color-tint($non-working-hours-call-color, 20%)};
  --non-working-hours-call-dark: #{color-shade($non-working-hours-call-color, 20%)};

  --agent-free-call-base: #{$agent-free-call-color};
  --agent-free-call-light: #{color-tint($agent-free-call-color, 20%)};
  --agent-free-call-dark: #{color-shade($agent-free-call-color, 20%)};

  --hangup-at-welcome-call-base: #{$hangup-at-welcome-call-color};
  --hangup-at-welcome-call-light: #{color-tint($hangup-at-welcome-call-color, 20%)};
  --hangup-at-welcome-call-dark: #{color-shade($hangup-at-welcome-call-color, 20%)};

  --ongoing-call-base: #{$ongoing-call-color};
  --ongoing-call-light: #{color-tint($ongoing-call-color, 20%)};
  --ongoing-call-dark: #{color-shade($ongoing-call-color, 20%)};

  --connection-failed-call-base: #{$connection-failed-call-color};
  --connection-failed-call-light: #{color-tint($connection-failed-call-color, 20%)};
  --connection-failed-call-dark: #{color-shade($connection-failed-call-color, 20%)};

  --busy-call-base: #{$busy-call-color};
  --busy-call-light: #{color-tint($busy-call-color, 20%)};
  --busy-call-dark: #{color-shade($busy-call-color, 20%)};

  --failed-call-base: #{$failed-call-color};
  --failed-call-light: #{color-tint($failed-call-color, 20%)};
  --failed-call-dark: #{color-shade($failed-call-color, 20%)};

  --no-answer-call-base: #{$no-answer-call-color};
  --no-answer-call-light: #{color-tint($no-answer-call-color, 20%)};
  --no-answer-call-dark: #{color-shade($no-answer-call-color, 20%)};
}
