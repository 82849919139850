@use '@angular/material' as mat;
@use 'sass:map';

@mixin status-icon($color: initial, $background-color: initial) {
  mat-icon {
    color: $color;
    margin-right: 5px;
  }
}

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $next-theme: mat.define-palette(mat.$red-palette);
  $matToolbarBackground: mat.get-color-from-palette($primary, 900);
  $primary-contrast: mat.get-color-from-palette($primary, '500-contrast');

  span.mat-calendar-body-cell-content.mat-focus-indicator,
  .mat-calendar-body-cell-container,
  button.mat-calendar-body-cell.mat-calendar-body-active {
    &:hover {
      color: $primary-contrast;
    }
  }

  $completed: #228b22;

  $component-density: -3;

  .mat-mdc-form-field {
    @include mat.form-field-density($component-density);
  }

  .mat-mdc-form-field-icon-suffix,
  button.input-suffix-button,
  button.mdc-icon-button.mat-mdc-icon-button {
    @include mat.form-field-density($component-density);
    @include mat.icon-button-density($component-density);
    font-size: 14px;
  }

  .mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-selected-checkmark-color: whitesmoke;
  }

  .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }

  // TODO: Find better way to do this. Trough custom theme config?
  button.mat-mdc-raised-button.mat-primary {
    color: $primary-contrast !important;
  }

  button.mat-mdc-raised-button.mat-primary {
    color: $primary-contrast !important;
  }

  // .mat-mdc-form-field-icon-suffix {
  //   .mat-mdc-icon-button {
  //     mat-icon {
  //       background-color: white !important;
  //     }
  //   }
  // }

  .mat-primary.mat-mdc-outlined-button:not(:disabled) {
    border-color: mat.get-color-from-palette($primary, 500) !important;
  }

  .mat-accent.mat-mdc-outlined-button:not(:disabled) {
    border-color: mat.get-color-from-palette($accent) !important;
  }

  tr.mat-mdc-header-row {
    --mat-table-header-headline-color: rgba(0, 0, 0, 0.54);
    color: rgba(0, 0, 0, 0.45);
  }

  .cdk-overlay-pane div.mat-mdc-select-panel {
    max-height: 330px; // Default is 275px
  }

  .cdk-overlay-pane div.mat-mdc-autocomplete-panel {
    max-height: 310px; // Default is 256px
  }

  .business-days-close {
    color: mat.get-color-from-palette($warn);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  mat-chip {
    cursor: pointer;

    &.active {
      color: white;
      background: mat.get-color-from-palette($primary);
    }
  }

  .toolbar-container {
    width: 64px;
    padding-bottom: 5px;
    padding-top: 5px;
    height: 100vh;
    position: fixed;
    left: 0px;
    z-index: 300;
    background-color: mat.get-color-from-palette($primary);
    transition: background-color 0.5s ease;
    transition: height 0.25s ease-in-out 0s;

    mat-nav-list.collapsed {
      a {
        mat-icon {
          color: rgba(255, 255, 255, 0.87);
        }
      }

      a.active-link {
        transition: background-color, box-shadow 0.5s ease;
        box-shadow: inset 4px 0 0 mat.get-color-from-palette($primary, 300);
        background: mat.get-color-from-palette($primary, 500);
        border-radius: 0px;
      }
    }

    .mat-mdc-list-base {
      &:hover {
        --mdc-list-list-item-hover-leading-icon-color: mat.get-color-from-palette(
          $primary,
          300
        );
        color: mat.get-color-from-palette($primary, 300);
      }
    }
  }

  .expanded-nav-item.active-link {
    transition: box-shadow, background-color 0.5s ease-in;
    box-shadow: inset 4px 0 0 mat.get-color-from-palette($primary, 300);
    background-color: lighten($color: #3f51b5, $amount: 49%);
  }

  span.reminderText {
    cursor: pointer;
    color: gray;

    &.active {
      color: mat.get-color-from-palette($accent);
    }
  }

  .active-link {
    transition: box-shadow, background-color 0.5s ease-in;
    box-shadow: inset 4px 0 0 mat.get-color-from-palette($primary, 300);
    // background-color: lighten($color: #3f51b5, $amount: 49%)
  }

  .maturity-day-onhover:hover {
    background-color: mat.get-color-from-palette($background, hover);
  }

  .material-text-color {
    color: mat.get-color-from-palette($foreground, text) !important;
  }

  .add-columns-border {
    border-width: 1px;
    border-style: solid;
    border-color: mat.get-color-from-palette($foreground, divider);
    padding-left: 16px;
    padding-top: 16px;
  }

  .progress-bar-total {
    width: 100%;
    height: 10px;
    display: inline-block;
    background: mat.get-color-from-palette($background, 'status-bar');
    margin: 0px;
  }

  .not-correct {
    color: mat.get-color-from-palette($accent);
  }

  mat-icon.reminderText {
    cursor: pointer;
    color: gray;

    &.active {
      color: mat.get-color-from-palette($accent);
    }
  }

  .TODAY {
    background-color: mat.get-color-from-palette($accent);
    color: white;
  }

  .MODIFIED {
    background-color: white;
    color: mat.get-color-from-palette($accent);
  }

  div.action-bar {
    button {
      transition: color 0.5s ease-in;

      mat-icon {
        color: #7e7e7e;
      }
    }

    span {
      mat-icon {
        cursor: help;
      }

      margin-right: 5px;
    }

    span,
    button {
      &.active mat-icon {
        color: mat.get-color-from-palette($accent);
      }
    }
  }

  .expanded-nav-item.active-link {
    transition: box-shadow, background-color 0.5s ease-in;
    box-shadow: inset 4px 0 0 mat.get-color-from-palette($primary, 300);
    background-color: lighten($color: #3f51b5, $amount: 49%);
  }

  div.transaction-status {
    &.completed {
      @include status-icon($completed, -color);
    }

    &.canceled {
      @include status-icon(mat.get-color-from-palette($warn), -color);
    }

    &.processing {
      @include status-icon(mat.get-color-from-palette($primary), -color);
    }

    &.sent {
      @include status-icon(mat.get-color-from-palette($primary), -color);
    }
  }

  .itfg-list-item-hover:hover {
    background: mat.get-color-from-palette($background, 'hover');
  }

  mat-tab-group.sticky-tabs-applied mat-tab-header {
    top: 0;
    position: sticky;
    z-index: 3;
    background-color: mat.get-color-from-palette($background, background);
  }

  .itfg-warn {
    color: mat.get-color-from-palette($warn);
  }

  .no-reports-info {
    color: mat.get-color-from-palette($primary);
    margin-left: 5px;
  }

  .mi-verified {
    div.identity-verification-title {
      color: #2ea76c !important;
    }
  }

  .mi-no-face-verification {
    div.identity-verification-title {
      color: mat.get-color-from-palette($primary) !important;
    }
  }

  .flex-offset-5 {
    margin-left: 5% !important;
  }

  .flex-offset-10 {
    margin-left: 10% !important;
  }

  .mat-mdc-standard-chip {
    --mdc-chip-label-text-color: #fff;
    text-shadow: 0 0 2px #5b5b5b;
  }

  mat-form-field.mat-mdc-form-field {
    div.mat-mdc-form-field-icon-suffix {
      padding: 0px 4px 0px 4px;

      & > mat-icon {
        padding: 10px;
        padding-right: 6px;
      }
    }
  }

  .pulse-loader {
    display: flex;
    align-items: center;
  
    > div {
      margin-right: 10%;
      width: 15px;
      height: 10px;
      background-color: mat.get-color-from-palette($accent);
      border-radius: 100%;
      animation: pulse 1s ease infinite;
  
      &:nth-child(2) {
        animation-delay: 100ms;
      }
  
      &:nth-child(3) {
        margin-right: 0;
        animation-delay: 200ms;
      }
    }
  }
  
  @keyframes pulse {
    0%, 60%, 100% {
      opacity: 1;
      transform: scale(1);
    }
  
    30% {
      opacity: .1;
      transform: scale(.01);
    }
  }

  @keyframes jiggle {
    0% { transform: rotate(-5deg); }
    50% { transform: rotate(5deg); }
    100% { transform: rotate(-5deg); }
  }
  
  .ringing-icon {
    animation: jiggle 0.5s infinite;
  }

  .progress-spinner-container {
    text-align: center;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .progress-spinner {
    margin: auto;
  }
}
