@use '@angular/material' as mat;
$material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';
@import 'material-design-icons-iconfont/src/material-design-icons';
@import './theming/themes/dev-theme.scss';
@import './theming/flex-layout';
@include mat.all-component-typographies();
@include mat.core();
@include covalent-layout();
@import './theming/_variables.scss';

mat-form-field {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field-text-suffix {
    display: flex;
    align-items: center;
    align-self: center;
  }
}

.mat-mdc-tab-body-content {
  overflow: hidden;
}

$edit-screen-title-font-size: 1.6em;
$edit-screen-card-title-font-size: 1.4em;

body {
  margin: 0;
  font-family: Roboto, sans-serif !important;
}

// Brand-specific stylings
.GC {
  color: #8bbc09;
}

.SC {
  color: #ff720b;
}

* {
  box-sizing: border-box;
}

.edit-screen-title {
  font-size: $edit-screen-title-font-size;
}

.edit-screen-card-title {
  font-size: $edit-screen-card-title-font-size !important;
}

.edit-screen-body>*,
.edit-screen-header>* {
  padding: 0 10px;
}

.edit-screen-header {
  min-height: 65px;
}

.edit-screen-body mat-card-title {
  font-size: inherit;
}

.edit-screen-body mat-card {
  margin: 20px 0;
}

.list-screen-operations-item:not(:last-of-type) {
  margin-right: 20px;
}

.modal-dialog-title {
  font-size: 24px;
  font-weight: 400;
}

//itfg-data-table-scrollable was initially set to 102%, probably because of credit-related-people component
// if the class is locally modified, does not fix the problem.
//pls, pay attention for any other changed default classes
// TODO: check how is affecting credit listing screen after credit-list-sorting branch is merged
.itfg-data-table-scrollable {
  width: 100%;
}

.column-gap-5 {
  // column-gap: 5px;
  column-gap: 6px;
}

[layout='row'] {
  column-gap: var(--flex-gap);
}

.column-gap-10 {
  column-gap: 10px;
}

.row-gap-10 {
  row-gap: 1.25em;

  [layout='row'] {
    column-gap: 5px;
  }
}

[no-flex-gap] {
  --flex-gap: 0px;
  column-gap: var(--flex-gap);
}

.full-width {
  width: 100%;
}

.no-padding {
  padding: 0;
}

.flex-padding {
  padding-right: 16px;
}

.flex-padding-24 {
  padding-right: 24px;
}

.flex-padding-margin-fix {
  margin-right: -16px;
}

.flex-padding-24-margin-fix {
  margin-right: -24px;
}

.toggle-right-scrollbar-fix {
  padding-right: 3px;
}

.table-cell-overflow-ellipsis {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button-color-not-active {
  color: gray;
}

.position-buttons-fix {
  position: absolute;
  top: -10px;
  right: 0;
}

.cdk-drag-handle {
  cursor: move;
}

$snackbar-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
  0px 6px 10px 0px rgba(0, 0, 0, 0.14),
  0px 1px 18px 0px rgba(0, 0, 0, 0.12);

.mat-mdc-snack-bar-container {
  &.info {
    --mat-snack-bar-button-color: ghostwhite;
    box-shadow:
      inset 6px 0 0 #03a9f4,
      $snackbar-shadow;
    --mdc-snackbar-container-color: #494c5f;
  }

  &.success {
    --mat-snack-bar-button-color: ghostwhite;
    box-shadow:
      inset 6px 0 0 #49974d,
      $snackbar-shadow;
    --mdc-snackbar-container-color: #5cbb61;
  }

  &.error {
    --mat-snack-bar-button-color: ghostwhite;
    box-shadow:
      inset 6px 0 0 #ca372d,
      $snackbar-shadow;
    --mdc-snackbar-container-color: #ea4f43;
  }

  &.warning {
    box-shadow:
      inset 6px 0 0 #ca372d,
      $snackbar-shadow;
    --mdc-snackbar-container-color: #494c5f;
    padding: 6px 16px;
    --mat-snack-bar-button-color: #ca372d;
    font-weight: bold;
  }

  &.notification {
    box-shadow:
      inset 6px 0 0 #03a9f4,
      $snackbar-shadow;
    --mdc-snackbar-container-color: #494c5f;
    padding: 6px 16px;
    --mat-snack-bar-button-color: #03a9f4;
  }

  &.connecting {
    --mat-snack-bar-button-color: ghostwhite;
    box-shadow:
      inset 6px 0 0 #ffc054,
      $snackbar-shadow;
    --mdc-snackbar-container-color: #ffa402;
  }
}

.mat-mdc-snack-bar-action button {
  color: ghostwhite;
}

.flag-icon {
  margin-right: 4px;
  line-height: 1.3em;
}

.flag-icon-unknown {
  background-image: url('/assets/images/Incognito.svg');
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-position {
  margin-top: 24px;
}

.mat-mdc-card {
  padding: 0;
  margin: 8px;
}

.mat-mdc-card-title {
  padding: 16px;
}

.mat-mdc-tab-body {
  .mat-mdc-tab-body-content {
    overflow: hidden;
  }
}

.mat-mdc-dialog-surface {
  padding: 20px;
}

div.mat-mdc-dialog-title,
div.mdc-dialog__title {
  padding: 0 0 9px;
}

// Only use if there are no mat-hints or mat-errors presented in the mat-form-fields, otherwise - it breaks
.remove-mat-form-field-wrapper {
  .mat-mdc-form-field-subscript-wrapper {
    margin-bottom: -1em !important;
  }

  .call-status-field .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}


button.mat-mdc-raised-button .mat-icon {
  margin: 0;
}

button.mat-mdc-outlined-button .mat-icon {
  margin: 0;
}

.mat-expansion-panel.navigation-expansion-panel {
  &.shadow-none {
    box-shadow: none !important;
  }

  div.mat-expansion-panel-content {
    div.mat-expansion-panel-body {
      padding: 0 !important;
    }
  }

  .mat-expansion-panel-header {
    font-size: initial !important;

    &.mat-expanded {
      height: 48px;
    }
  }
}

.icon-header {
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  display: flex;
}

.mat-drawer-content {
  scrollbar-gutter: stable;
}

.right-drawer {
  .mat-drawer-inner-container {
    scrollbar-gutter: stable;
  }
}

::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #c2c2c2;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #c2c2c2;
}

//

itfg-main-layout .right-drawer .mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}

%heading {
  padding-left: 16px;
  font-size: 1.1em;
  background-color: whitesmoke;
  font-weight: 500;
  border-radius: 3px 3px 0 0;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mat-mdc-icon-button {
    transform: scale(0.9);

    &.static {
      pointer-events: none;
    }
  }
}

itfg-pbx {
  .decorated-heading {
    @extend %heading;

    border-left: 4px solid $serenity;
  }

  .call-tag {
    background-color: $lightgrayish !important;
    color: whitesmoke;
  }
}

itfg-event-log-filtering {
  .decorated-heading {
    @extend %heading;

    border-left: 4px solid $greenery;
  }
}

itfg-event-log-notes {
  .decorated-heading {
    @extend %heading;

    border-left: 4px solid $mimosa;
  }
}

itfg-payment-promises .custom-button,
itfg-reminder .custom-button {
  flex: 1;
  justify-content: start;

  .material-symbols-outlined {
    font-variation-settings: 'FILL' 0;
  }

  .mdc-button__label {
    // font-size: 1rem;
    // font-weight: 400;
    flex: 1;
  }
}

.fill {
  font-variation-settings: 'FILL' 1;
}

.grey {
  color: rgba(0, 0, 0, 0.4392156863);
}

.mdc-checkbox__ripple {
  display: none;
}

itfg-call-hub .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  align-items: center;
}